//import Headroom from "react-headroom";
import type {NextComponentType} from 'next';
import Image from 'next/image';
import Link from 'next/link';
import HomeIcon from '../images/home-icon.svg';
import HomeIconActive from '../images/home-active-icon.svg';
import searchIcon from '../images/search-icon.svg';
import searchIconActive from '../images/search-active-icon.svg';
import patronIcon from '../images/patreon-icon.svg';
import patronIconActive from '../images/patreon-icon.svg';
import bitsIcon from '../images/bits-icon.svg';
import HomeIconWhite from '../images//home-white.svg';
import HomeIconWhiteActive from '../images/home-white-active.svg';
import searchWhiteIcon from '../images/search-white.svg';
import searchWhiteIconActive from '../images/search-white-active.svg';
import patronWhiteIcon from '../images/patreon-white.svg';
import patronWhiteIconActive from '../images/patreon-white-active.svg';
import bitsWhiteIcon from '../images/bits-white.svg';
import bitsWhiteIconActive from '../images/bits-white-active.svg';
import {useRouter} from 'next/router';
const BottomHeader: NextComponentType = () => {
	const router = useRouter();
	return (
		// <Headroom
		// 	style={{
		// 		WebkitTransition: 'all .5s ease-in-out',
		// 		MozTransition: 'all .5s ease-in-out',
		// 		OTransition: 'all .5s ease-in-out',
		// 		transition: 'all .5s ease-in-out',
		// 		zIndex: '999',
		// 	}}>
		<div id='hideMobileApp'>
			{/* For Home Desktop */}
			<div className='desktop-icon fixed left-[50%] bottom-[30px]  z-50 bg-white rounded-xl py-2 px-4 items-center justify-content-center home-nav sm:block hidden'>
				<ul className='flex items-center justify-between'>
					<li className={router.pathname == '/' ? 'pageactive px-2' : 'nonactive px-2'}>
						<Link href='/'>
							<a className={router.pathname == '/' ? 'block px-4 bg-[rgba(248,201,51,30%)] py-2 rounded-xl' : 'block px-4 py-2'}>
								<span className='hidenonactive'>
									<Image src={HomeIcon} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='showactive'>
									<Image src={HomeIconActive} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
							</a>
						</Link>
					</li>
					<li className={router.pathname == '/padhaaisearch' ? 'pageactive px-2' : 'nonactive px-2'}>
						<Link href='/padhaaisearch'>
							<a className={router.pathname == '/padhaaisearch' ? 'block px-4 bg-[rgba(248,201,51,30%)] py-2 rounded-xl' : 'block px-4 py-2'}>
								<span className='hidenonactive'>
									{/* <i className="fal fa-search align-middle text-[20px]"></i> */}
									<Image src={searchIcon} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='showactive'>
									{' '}
									<Image src={searchIconActive} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
							</a>
						</Link>
					</li>
					<li className={router.pathname == '' ? 'pageactive px-2' : 'nonactive px-2'}>
						<Link href='https://milaap.org/fundraisers/support-super-girls-k?utm_medium=whatsapp_status&utm_source=app'>
							<a className={router.pathname == '' ? 'block px-4 bg-[rgba(248,201,51,30%)] py-2 rounded-xl' : 'block px-4 py-2'} target="_blank">
								<span className='hidenonactive'>
									<Image src={patronIcon} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='showactive'>
									<Image src={patronIconActive} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
							</a>
						</Link>
					</li>
					<li className={router.pathname == '/bits' ? 'pageactive px-2' : 'nonactive px-2'}>
						<Link href='/bits'>
							<a className='block px-4  py-2 rounded-xl'>
								<Image src={bitsIcon} width={20} height={20} alt='' className='cursor-pointer flex-shrink-0' />
								{/* <span className="text-sm ml-1">Bits</span> */}
							</a>
						</Link>
					</li>
				</ul>
			</div>
			{/* For bits Desktop */}
			<div className='desktop-icon fixed left-[50%] bottom-[30px] z-50 rounded-xl py-2 px-4 items-center justify-content-center bits-nav bits-nav-desktop sm:block hidden'>
				<ul className='flex items-center justify-between'>
					<li className={router.pathname == '/' ? 'pageactive px-2' : 'nonactive px-2'}>
						<Link href='/'>
							<a className='block px-4 py-2 rounded-xl'>
								<span className='hidenonactive'>
									<Image src={HomeIconWhite} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='showactive'>
									<Image src={HomeIconWhiteActive} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
							</a>
						</Link>
					</li>
					<li className={router.pathname == '/padhaaisearch' ? 'pageactive px-2' : 'nonactive px-2'}>
						<Link href='/padhaaisearch'>
							<a className='block px-4 py-2 rounded-xl'>
								<span className='hidenonactive'>
									{/* <i className="fal fa-search align-middle text-[20px]"></i> */}
									<Image src={searchWhiteIcon} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='showactive'>
									{' '}
									<Image src={searchWhiteIconActive} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
							</a>
						</Link>
					</li>
					<li className={router.pathname == '' ? 'pageactive px-2' : 'nonactive px-2'}>
						<Link href='https://milaap.org/fundraisers/support-super-girls-k?utm_medium=whatsapp_status&utm_source=app'>
							<a className='block px-4 py-2 rounded-xl' target='_blank'>
								<span
									className='hidenon
			  active'>
									{' '}
									<Image src={patronWhiteIcon} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='showactive'>
									{' '}
									<Image src={patronWhiteIconActive} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
							</a>
						</Link>
					</li>
					<li className={router.pathname == '/bits' ? 'pageactive px-2' : 'nonactive px-2'}>
						<Link href='/bits'>
							<a className='block px-4 py-2 rounded-xl'>
								<Image src={bitsWhiteIconActive} width={20} height={20} alt='' className='cursor-pointer flex-shrink-0' />
								{/* <span className="text-sm ml-1">Bits</span> */}
							</a>
						</Link>
					</li>
				</ul>
			</div>
			{/* For Home Mobile */}
			<div className='sm:hidden block fixed bottom-0 w-full bg-white z-50 shadow-card bottom-nav home-nav home-nav-mobile'>
				<div className='flex items-center justify-between py-2'>
					<div className={router.pathname == '/' ? 'flex-1 text-center pageactive' : 'flex-1 text-center nonactive'}>
						<Link href='/'>
							<a className='block'>
								<span className='hidenonactive'>
									<Image src={HomeIcon} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='showactive'>
									<Image src={HomeIconActive} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='block text-xs mt-1'>Home</span>
							</a>
						</Link>
					</div>
					<div className={router.pathname == '/padhaaisearch' ? 'flex-1 text-center pageactive' : 'flex-1 text-center nonactive'}>
						<Link href='/padhaaisearch'>
							<a>
								<span className='hidenonactive'>
									{' '}
									<Image src={searchIcon} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='showactive'>
									{' '}
									<Image src={searchIconActive} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='block text-xs mt-1'>Search</span>
							</a>
						</Link>
					</div>
					<div className={router.pathname == '' ? 'flex-1 text-center pageactive' : 'flex-1 text-center nonactive'}>
						<Link href='https://milaap.org/fundraisers/support-super-girls-k?utm_medium=whatsapp_status&utm_source=app'>
							<a target="_blank">
								<span className='hidenonactive'>
									{' '}
									<Image src={patronIcon} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='showactive'>
									{' '}
									<Image src={patronIconActive} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='block text-xs mt-1'>Patreon</span>
							</a>
						</Link>
					</div>
					<div className={router.pathname == '/bits' ? 'flex-1 text-center pageactive' : 'flex-1 text-center nonactive'}>
						<Link href='/bits'>
							<a>
								<Image src={bitsIcon} width={20} height={20} alt='' className='cursor-pointer' />
								<span className='block text-xs mt-1'>Bits</span>
							</a>
						</Link>
					</div>
				</div>
			</div>
			{/* For Bits Mobile */}
			<div className='sm:hidden block fixed bottom-0 w-full  z-50 shadow-card bottom-nav bits-nav bits-nav-mobile'>
				<div className='flex items-center justify-between py-3'>
					<div className={router.pathname == '/' ? 'flex-1 text-center pageactive' : 'flex-1 text-center nonactive'}>
						<Link href='/'>
							<a>
								<span className='hidenonactive'>
									<Image src={HomeIconWhite} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='showactive'>
									<Image src={HomeIconWhiteActive} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='block text-white text-xs mt-1'>Home</span>
							</a>
						</Link>
					</div>
					<div className={router.pathname == '/padhaaisearch' ? 'flex-1 text-center pageactive' : 'flex-1 text-center nonactive'}>
						<Link href='/padhaaisearch'>
							<a>
								<span className='hidenonactive'>
									<Image src={searchWhiteIcon} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='showactive'>
									<Image src={searchWhiteIconActive} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='block text-white text-xs mt-1'>Search</span>
							</a>
						</Link>
					</div>
					<div className={router.pathname == '' ? 'flex-1 text-center pageactive' : 'flex-1 text-center nonactive'}>
						<Link href='https://milaap.org/fundraisers/support-super-girls-k?utm_medium=whatsapp_status&utm_source=app'>
							<a target="_blank">
								<span className='hidenonactive'>
									<Image src={patronWhiteIcon} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='showactive'>
									<Image src={patronWhiteIconActive} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='block text-white text-xs mt-1'>Patreon</span>
							</a>
						</Link>
					</div>
					<div className={router.pathname == '/bits' ? 'flex-1 text-center pageactive' : 'flex-1 text-center nonactive'}>
						<Link href='/bits'>
							<a>
								<span className='hidenonactive'>
									<Image src={bitsWhiteIcon} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='showactive'>
									<Image src={bitsWhiteIconActive} width={20} height={20} alt='' className='cursor-pointer' />
								</span>
								<span className='block text-white text-xs mt-1'>Bits</span>
							</a>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};
export default BottomHeader;